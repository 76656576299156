import Vue from 'vue';
import axios from 'axios';

const menuIcons = {
  ip: 'mdi-ip',
  auth: 'mdi-key',
  board: 'mdi-clipboard-multiple',
  group: 'mdi-sitemap',
  account: 'mdi-account-cog',
  work: 'mdi-clipboard-text-clock',
  cs: 'mdi-headset',
  product: 'mdi-archive-edit',
  db: 'mdi-account-details',
  customer: 'mdi-account-multiple',
  payment: 'mdi-credit-card-edit',
  sales: 'mdi-finance',
  leader: 'mdi-account-tie',
  salary: 'mdi-clipboard-account',
  boards: 'mdi-clipboard-edit-outline',
  pg: 'mdi-credit-card-plus',
  rooms: 'mdi-chat-plus',
};
export default {
  namespaced: true,
  state: {
    connected: false,
    initialized: false,
    userInfo: null,
    companyInfo: {
      name: '',
      code: '',
      path: '',
      receptionNumber: '',
    },
    token: null,
    verified: null,
    menus: null,
  },
  getters: {
    userInfo(state) {
      return state.userInfo;
    },
    companyInfo(state) {
      return state.companyInfo;
    },
    connected(state) {
      return state.connected;
    },
    initialized(state) {
      return state.initialized;
    },
    token(state) {
      return state.token;
    },
    verified(state) {
      return (state.userInfo != null);
    },
    menus(state) {
      return state.menus;
    },
  },
  mutations: {
    connected(state, value) {
      state.connected = value;
    },
    initialized(state, value) {
      state.initialized = value;
    },
    userInfo(state, value) {
      state.userInfo = value;
    },
    companyInfo(state, value) {
      state.companyInfo = value;
    },
    token(state, value) {
      state.token = value;
    },
    verified(state, value) {
      state.verified = value;
    },
    menus(state, value) {
      state.menus = value;
    },
  },
  actions: {
    'socket.connect': ({ state, commit, dispatch }) => {
      commit('connected', true);
      console.log('socket connected');
      dispatch('appInitialized');
      if (state.userInfo === null) dispatch('dialog/alert', ['success', '접속되었습니다.'], { root: true });
    },
    'socket.error': (_, error) => {
      console.log('socket error:', error);
    },
    'socket.reconnect_failed': ({ state, commit, dispatch }, error) => {
      console.log('socket error:', error);
      console.log('reconnect_failed');
      commit('initialized', false);
      commit('connected', false);
      commit('verified', false);
      commit('userInfo', null);
      localStorage.removeItem(`MANOadmin.${state.companyInfo.code}.authToken`);
      dispatch('dialog/alert', ['error', '재접속에 실패하였습니다. 인터넷 상태를 확인해주세요.', false], { root: true });
    },
    'socket.disconnect': ({ dispatch }, _, resp) => {
      console.log('socket disconnect:', resp);
      dispatch('dialog/alert', ['error', '통신 오류가 발생했습니다.'], { root: true });
    },
    logout({ state, commit, dispatch }) {
      localStorage.removeItem(`MANOadmin.${state.companyInfo.code}.authToken`);
      commit('verified', false);
      commit('userInfo', null);
      dispatch('authorization');
    },

    async getIPAddress({ dispatch }) {
      try {
        const response = await axios.get('https://api.ip.pe.kr/json/');
        return response.data.ip;
      } catch (error) {
        dispatch('dialog/alert', ['error', '통신 오류가 발생했습니다.'], { root: true });
        this.ipLoading = false;
        console.error(error);
        return false;
      }
    },

    // 회사정보 가져오기
    async appInitialized({ state, commit, dispatch }) {
      (new Vue()).$socket.emit('configs.get', {
        key: 'info',
      }, (resp) => {
        if (resp.result === 'success') {
          commit('companyInfo', resp.value.value);
          document.title = `${state.companyInfo.name} 관리자`;
          console.log('app initialized');
          dispatch('authorization');
        } else {
          dispatch('dialog/alert', ['error', '오류가 발생했습니다.'], { root: true });
          console.error(resp);
        }
      });
    },

    async authorization({ state, commit, dispatch }, type = null) {
      commit('dialog/progress', true, { root: true });
      const success = (user) => {
        commit('dialog/progress', false, { root: true });
        commit('userInfo', user);
        commit('verified', true);
        commit('initialized', true);
        console.log('user verified');
        if (type !== 0) dispatch('dialog/alert', ['success', `${user.name} 님 반갑습니다.`], { root: true });
      };
      const fail = () => {
        commit('verified', false);
        commit('initialized', true);
        commit('userInfo', null);
        commit('dialog/progress', false, { root: true });
      };
      const token = localStorage.getItem(`MANOadmin.${state.companyInfo.code}.authToken`);
      if (token) {
        (new Vue()).$socket.emit('users.token.verify', {
          token,
        }, async (resp) => {
          if (resp.result === 'success') {
            const user = JSON.parse(JSON.stringify(resp.userInfo));
            // IP 제한은 admin 제외
            if (user.id !== 'admin') {
              const myIP = await dispatch('getIPAddress');
              (new Vue()).$socket.emit('configs.get', {
                key: 'ip',
              }, async (response) => {
                if (response.result === 'success' && myIP !== false) {
                  const allowIPArray = response.value.value;
                  const myIPArray = myIP.split('.');
                  myIPArray[myIPArray.length - 1] = '*';
                  const myIPnet = myIPArray.join('.');
                  const allowCheck = allowIPArray.filter((row) => row.ip === myIPnet).length > 0;
                  if (allowIPArray.length === 0 || allowCheck === true) {
                    success(user);
                  } else {
                    dispatch(
                      'dialog/alert', ['error', `접속이 허용되지 않은 아이피입니다. (${myIP})`], { root: true },
                    );
                    fail();
                  }
                } else {
                  fail();
                }
              });
            } else {
              success(user);
            }
          } else {
            fail();
            localStorage.removeItem(`MANOadmin.${state.companyInfo.code}.authToken`);
          }
        });
      } else { // 토큰이 없을 경우
        fail();
      }
    },
    async permissionToMenus({ state, commit, dispatch }, per) {
      const menuArr = [
        { path: '', name: '대시보드', icon: 'mdi-view-dashboard' },
        { path: 'boards-0', name: '공지사항', icon: 'mdi-bullhorn' },
      ];
      if (state.userInfo.leaderGroups.length > 0) {
        menuArr.push(
          { path: 'leader-customer', name: '그룹 고객관리', icon: 'mdi-account-box-multiple' },
          { path: 'leader-sales', name: '그룹 매출/환불', icon: 'mdi-chart-box' },
        );
      }
      if (state.userInfo.isSales === 1) {
        menuArr.push(
          { path: 'manager-customer', name: '나의 고객관리', icon: 'mdi-account-group' },
          { path: 'manager-sales', name: '나의 매출/환불', icon: 'mdi-chart-bar' },
          { path: 'manager-salary', name: '나의 급여관리', icon: 'mdi-clipboard-account' },
        );
      }
      const keys = Object.keys(per);
      const permission = { ...per };
      (new Vue()).$socket.emit('board.list.get', {
        page: 1,
        itemsPerPage: 0,
        sortBy: [],
        sortDesc: [],
        filters: [
          {
            condition: 'ne',
            column: 'no',
            value: 0,
          },
        ],
      }, (resp) => {
        if (resp.result === 'success') {
          const boards = resp.items;
          for (let i = 0; i < keys.length; i += 1) {
            const menus = { ...permission[keys[i]].menu };
            const menuKeys = Object.keys(menus);
            for (let j = 0; j < menuKeys.length; j += 1) {
              if (menuKeys[j] !== 'notice' && menus[menuKeys[j]].bool === true) {
                if (keys[i] === 'boards') {
                  const isDisable = boards.find((r) => String(r.no) === menuKeys[j]) === undefined;
                  if (isDisable === false) {
                    menuArr.push({
                      path: `boards-${menuKeys[j]}`,
                      icon: menuIcons.boards,
                      name: menus[menuKeys[j]].name,
                    });
                  }
                } else if (menuKeys[j] !== 'sms' && menuKeys[j] !== 'regist') {
                  menuArr.push({
                    path: menuKeys[j],
                    icon: menuIcons[menuKeys[j]],
                    name: menus[menuKeys[j]].name,
                  });
                }
              }
            }
            commit('menus', menuArr);
          }
        } else {
          dispatch('dialog/alert', ['error', `오류가 발생했습니다. 다시 시도해주세요. (${resp.code})`, false], { root: true });
          console.error(resp);
        }
      });
    },
  },
};
