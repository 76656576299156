<template>
  <v-app id="app">
    <progress-circle/>
    <dlg-alert/>
    <dlg-notification/>
    <dlg-confirm
      :show.sync="confirm.show"
      :func="confirm.func"
      :msg="confirm.msg"
      :btnText="confirm.btnText"
      :color="confirm.color"
    />
    <dlg-result/>
    <dlg-upload-task/>
    <dlg-post-view
      :show.sync="post.show"
      :item.sync="post.item"
      :type="post.type"
      :func="post.func"
    />
    <dlg-sales-view
      :show.sync="sale.show"
      :item.sync="sale.item"
      :func="sale.func"
    />
    <dlg-customer-memo
      :show.sync="memo.show"
      :data.sync="memo.data"
      :title="memo.title"
      :target="memo.target"
      :func="memo.func"
      :batch="memo.batch"
    />
    <router-view/>
  </v-app>
</template>
<script>
import ProgressCircle from '@/components/ProgressCircle.vue';
import DlgAlert from '@/components/dialog/DlgAlert.vue';
import DlgNotification from '@/components/dialog/DlgNotification.vue';
import DlgConfirm from '@/components/dialog/DlgConfirm.vue';
import DlgResult from '@/components/dialog/DlgResult.vue';
import DlgUploadTask from '@/components/dialog/DlgUploadTask.vue';
import DlgPostView from '@/components/dialog/DlgPostView.vue';
import DlgSalesView from '@/components/dialog/DlgSalesView.vue';
import DlgCustomerMemo from '@/components/dialog/DlgCustomerMemo.vue';
import { mapGetters, mapMutations, mapActions } from 'vuex';

export default {
  components: {
    DlgAlert,
    DlgNotification,
    ProgressCircle,
    DlgConfirm,
    DlgResult,
    DlgUploadTask,
    DlgPostView,
    DlgSalesView,
    DlgCustomerMemo,
  },
  data: () => ({
  }),
  computed: {
    ...mapGetters({
      connected: 'auth/connected',
      initialized: 'auth/initialized',
      userInfo: 'auth/userInfo',
      verified: 'auth/verified',
      confirm: 'dialog/confirm',
      post: 'dialog/post',
      sale: 'dialog/sale',
      memo: 'dialog/memo',
      route: 'router/route',
      path: 'router/path',
    }),
  },
  methods: {
    ...mapMutations({
      progress: 'dialog/progress',
      push: 'router/push',
    }),
    ...mapActions({
      authorization: 'auth/authorization',
      permissionToMenus: 'auth/permissionToMenus',
    }),
  },
  watch: {
    initialized: {
      immediate: true,
      handler() {
        this.$router.checkAuth();
      },
    },
    connected: {
      immediate: true,
      handler() {
        this.$router.checkAuth();
      },
    },
    verified: {
      immediate: true,
      handler() {
        console.log('verified watch:', this.verified);
        this.$router.checkAuth();
      },
    },
    userInfo: {
      deep: true,
      handler(val) {
        if (this.verified === true) {
          this.permissionToMenus(val.permission);
        }
      },
    },
    path: {
      handler(val) {
        if (val !== null) {
          this.$router.push(val);
          this.push(null);
        }
      },
    },
    route: {
      handler() {
        if (this.verified === true) {
          // this.authorization(0);
        }
      },
    },
  },
};
</script>
<style>
  html{
    overflow-y: auto;
    overflow-x: auto;
  }
  * {
    box-sizing: border-box;
    font-family: 'NotoSans';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  @font-face {
    font-family: 'NotoSans';
    src: url('assets/fonts/NotoSansKR-Thin.otf');
    font-weight: 100;
  }

  @font-face {
    font-family: 'NotoSans';
    src: url('assets/fonts/NotoSansKR-Light.otf');
    font-weight: 300;
  }

  @font-face {
    font-family: 'NotoSans';
    src: url('assets/fonts/NotoSansKR-Regular.otf');
    font-weight: 400;
  }

  @font-face {
    font-family: 'NotoSans';
    src: url('assets/fonts/NotoSansKR-Medium.otf');
    font-weight: 500;
  }

  @font-face {
    font-family: 'NotoSans';
    src: url('assets/fonts/NotoSansKR-Bold.otf');
    font-weight: 700;
  }

  @font-face {
    font-family: 'NotoSans';
    src: url('assets/fonts/NotoSansKR-Black.otf');
    font-weight: 900;
  }
  .contents-section {
    min-width: 1200px;
  }
  .download-list-excel-btn {
    display: none;
  }
</style>
